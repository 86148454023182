import { Box, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";

const OrderDetailPaymentSection = () => {
    return (
        <Box sx={{ marginY: 2 }}>
            <Paper variant="outlined" sx={{ paddingX: 2 }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                付款方式
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                信用卡
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                卡號
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                **** **** **** 1234
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                取貨方式
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                上門自取
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                地址
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                台北市信義區市府路1號
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    )
}

export default OrderDetailPaymentSection;